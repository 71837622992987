.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: #46ACC2;
  width: 30%;
  height: 60%;
  border-radius: 20px;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  text-align: center;

}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.pfp {
    display: block;
    margin: auto;
    margin-top: 50px;
    border-radius: 100px;;
}

.close {
    display: block;
    margin: auto;
    margin-top: 90px;
    cursor: pointer;
    width: 150px;
    height: 50px;
    border: solid 5px;
    background-color: #46ACC2;
    border-radius: 40px;
    color: #143642;
    font-size: 20px;
  }

  .username {
    font-size: 40px;
    font-family: Arial, Helvetica, sans-serif;
    color: #143642;
  }

  .score {
    font-size: 40px;
      font-family: Arial, Helvetica, sans-serif;
      color: #143642;
  }