.App-logo {
  display: block;
  margin: auto;
  width: 80vw;
}

.search {
  display: block;
  margin: auto;
  width: 95vw;
  text-align: center;

}
.searchform {
  display: block;
  margin: auto;

}

.searchbar {
  margin-top: 80px;
  width: 800px;
  height: 50px;
  border-radius: 40px;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #143642;
  border: solid 5px #46ACC2;
  outline: none;
  color: white;
}

.searchbutton {
  cursor: pointer;
  width: 150px;
  height: 50px;
  border: solid 5px ;
  background-color: #46ACC2;
  border-radius: 40px;
  color: #143642;
  font-size: 20px;
}

.tips {
  width: 100%;
  margin-top: 15%;
  text-align: center;
  position: relative;
  display: inline-block;
  color: #46ACC2;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
}

.tips .tiptext {
  visibility: hidden;
  width: 200px;
  background-color: #46ACC2;
  color: #143642;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  top: 100%;
  left: 50%;
  margin-left: -100px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tips:hover .tiptext {
  visibility: visible;
}

::placeholder {
  color: white;
}